import React from 'react';
import PropTypes from 'prop-types';

const Footer = (props) => (
  <footer id="footer" style={props.timeout ? { display: 'none' } : {}}>
    <div>
      <a href="https://staynavi.direct/" target="_blank" rel="noopener noreferrer">
        みやざき支援割はこちらから会員登録をして下さい
      </a>
    </div>
    <ul className="icons">
      <li><a href="https://twitter.com/KinosatoBB" className="icon fa-twitter" target="_blank"
             rel="noreferrer"><span
        className="label">Twitter</span></a></li>
      <li><a href="https://www.facebook.com/kinosatomorotsuka/" className="icon fa-facebook" target="_blank"
             rel="noreferrer"><span
        className="label">Facebook</span></a></li>
    </ul>
    <p className="copyright">&copy; 森の民宿 樹の里</p>
  </footer>
);

Footer.propTypes = {
  timeout: PropTypes.bool
};

export default Footer;
