import PropTypes from 'prop-types'
import React from 'react'

import roomSazanka from '../images/room-sazanka.jpg'
import roomMomiji from '../images/room-momiji.jpg'
import roomIchii from '../images/room-ichii.jpg'
import roomSakura from '../images/room-sakura.jpg'
import irori from '../images/irori.jpeg'
import dining from '../images/dining.jpeg'
import bath01 from '../images/bath-01.jpeg'
import bath02 from '../images/bath-02.jpeg'

class Main extends React.Component {

  render() {
    let close = (
      <div
        className='close'
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id='main'
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id='intro'
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className='major'>お部屋</h2>
          <div className='rooms'>
            <div>
              <img src={roomSazanka} alt='' />
              <p>
                洋室6畳<br />シングルベッド2つ
              </p>
            </div>
            <div>
              <img src={roomMomiji} alt='' />
              <p>
                和室6畳
              </p>
            </div>
            <div>
              <img src={roomIchii} alt='' />
              <p>
                和室8畳
              </p>
            </div>
            <div>
              <img src={roomSakura} alt='' />
              <p>
                和室6畳<br />禁煙部屋
              </p>
            </div>
          </div>

          <p>
            全室、アナログTV・冷暖房完備。<br />
            アメニティは、浴衣・フェイスタオル・歯ブラシをお部屋にご用意しております。<br />
            浴衣・タオルのお持ち帰りはご遠慮ください。
          </p>

          <h2 className='major'>囲炉裏端</h2>
          <img src={irori} alt='' />
          <p>
            和室10畳。<br />
            食事をとっていただくお部屋です。<br />
            10月下旬～4月末の間ご利用になれます。<br />
            囲炉裏に、火を入れない状態でしたら年間を通してご利用頂けます。
          </p>

          <h2 className='major'>食堂</h2>
          <img src={dining} alt='' />
          <p>
            大自然の中での生ビールは格別です。
          </p>

          <h2 className='major'>浴室</h2>

          <div className='rooms'>
            <div>
              <img src={bath01} alt='' />
            </div>
            <div>
              <img src={bath02} alt='' />
            </div>
          </div>
          <p>浴室は、1階にあり24時間入浴できます。<br />
            ボディソープとリンスインシャンプーあり。</p>

          {close}
        </article>

        <article
          id='price'
          className={`${this.props.article === 'price' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className='major'>料金（すべて税込）</h2>

          <h3>観光料金(1泊2食）</h3>
          <ul>
            <li>諸塚村の食材を使ったこだわり料理</li>
            <li>おとな(中学生以上) 9,900円</li>
            <li>こども(小学生未満) 5,400円</li>
          </ul>

          <h3>お仕事料金</h3>
          <ul>
            <li>おとな(中学生以上) 8,580円</li>
          </ul>

          <h3>宴会料金</h3>
          <ul>
            <li>おとな(中学生以上) 2,750円~(食事代のみ) ※ご希望に応じて</li>
            <li>こども(小学生未満) ご相談ください</li>
            <li>お食事内容 諸塚村こだわり料理</li>
          </ul>

          <p>
            その他ご予算・ご希望に応じますので、お気軽にご相談ください。<br />
            アレルギーにつきましても、ご予約の際にお知らせください。
          </p>

          <p>
            メールでのご質問などは受けつけておりますが、ご予約は行なっておりません。<br />
            ご予約の際はお電話にてお願い致します。<br />
            ぜひお客様自身の「声」をお聞かせ下さい！
          </p>

          <h3>お詫びとお願い</h3>
          <p>
            平素より、樹の里をご利用いただき、深く感謝しおります。<br />
            昨今の原材料や光熱費等の価格高騰により当民宿としても値上げを余儀なくされる状況となりました。<br />
            大変ご迷惑をお掛けすることとなりますが、ご理解頂くとともに今後もより一層サービスの向上に取り組み、皆様に喜んで頂ける様努めてまいりますので、変わらぬご愛顧を賜ります様重ねて宜しくお願いいたします。
          </p>
          {close}
        </article>


        <article
          id='food'
          className={`${this.props.article === 'food' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className='major'>お食事</h2>
          <h3>春</h3>
          <p>
            山菜(ワラビ、ノビル、タラノメ、ユキノシタ、ヨモギ、イタドリ)、筍、葉ワサビ、木の芽(山椒)、春椎茸
          </p>

          <h3>夏</h3>
          <p>
            新茶、梅、らっきょう、茄子、きゅうり、おくら、みょうが、ハスガラ、オクラ、ゴーヤ、鮎
          </p>

          <h3>秋</h3>
          <p>
            甘柿、猪肉、新米、椎茸、秋茄子、秋みょうが、蜂の子(特注)
          </p>

          <h3>冬</h3>
          <p>
            猪肉(特注)、鹿肉(特注)、椎茸
          </p>
          {close}
        </article>

        <article
          id='event'
          className={`${this.props.article === 'event' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className='major'>イベント</h2>
          <p>
            ここ諸塚村では一年を通して様々な行事が行なわれています。<br />
            古くから伝わる伝統行事や、新しい取組みとして生まれたものまでその殆んどが華々しくはありませんが、地元の生活に密着している、地元の人々のためのものです。かといって敷居が高いわけでもなく、飛び入りの客人は目の回るようなもてなしを受けるでしょう。<br />
            みなさんも興味があったら、人懐こい諸塚の住民たちとふれあってみませんか。<br />
            ※2012年9月～2013年3月の年間予定※（ 天候等により、予期なく変更されることもあります)
          </p>

          <h3>2012年9月(長月)</h3>
          <ul className='event-list'>
            <li>
              <span>9/8～9/9</span>
              <span>エコツアー「<a href='https://www.morotsuka-tourism.jp/spot/spot_cat/yamagakkou/' target='_blank'
                             ref='noreferrer'>諸塚でやま学校しよう！</a>」</span>
            </li>
          </ul>

          <h3>2012年10月(神無月)</h3>
          <ul className='event-list'>
            <li>
              <span>10/7</span>
              <span>諸塚村民体育大会</span>
            </li>
            <li>
              <span>10/13</span>
              <span>飯干秋祭り</span>
            </li>
            <li>
              <span>10/13～10/14</span>
              <span>エコツアー「<a href='https://www.morotsuka-tourism.jp/spot/spot_cat/yamagakkou/' target='_blank' rel='noreferrer'>諸塚でやま学校しよう！</a>」</span>
            </li>
            <li>
              <span>10/20</span>
              <span>南川秋祭り・八重の平秋季祭</span>
            </li>
            <li>
              <span>10/21</span>
              <span>川内・黒葛原秋祭り</span>
            </li>
            <li>
              <span>10/21</span>
              <span>諸塚中学校文化祭</span>
            </li>
            <li>
              <span>10/27</span>
              <span>立岩諸塚神社秋</span>
            </li>
            <li>
              <span>10/28</span>
              <span>小原井神社秋祭り</span>
            </li>
          </ul>

          <h3>2012年11月(霜月)</h3>
          <ul className='event-list'>
            <li>
              <span>11/2</span>
              <span>家代神社(青年演芸・臼太鼓)</span>
            </li>
            <li>
              <span>11/3</span>
              <span>川の口神社・塚原神社・七ツ山桂神社・滝ノ下・諸塚・穂白尾・合鴨秋祭</span>
            </li>
            <li>
              <span>11/4</span>
              <span>第14回黒岳もみじまつり</span>
            </li>
            <li>
              <span>11/4</span>
              <span>荒谷秋祭</span>
            </li>
            <li>
              <span>11/10</span>
              <span>桂霜月祭</span>
            </li>
            <li>
              <span>11/17</span>
              <span>第10回　諸塚駄賃つけ唄全国大会</span>
            </li>
            <li>
              <span>11/18</span>
              <span>第63回諸塚村民文化祭</span>
            </li>
            <li>
              <span>11/23</span>
              <span>松の平神社秋祭・七ッ山かあちゃん祭</span>
            </li>
            <li>
              <span>11/23～25</span>
              <span>エコツアー「<a href='https://www.morotsuka-tourism.jp/spot/spot_cat/yamagakkou/' target='_blank'
                             rel='noreferrer'>諸塚でやま学校しよう！</a>」</span>
            </li>
          </ul>

          <h3>2012年12月(師走)</h3>
          <ul className='event-list'>
            <li>
              <span>12/2</span>
              <span>第66回 村駅伝大会</span>
            </li>
            <li>
              <span>12/2～12/3</span>
              <span>奥畑おひまち</span>
            </li>
            <li>
              <span>12/8～12/9</span>
              <span>エコツアー「<a href='https://www.morotsuka-tourism.jp/spot/spot_cat/yamagakkou/' target='_blank'
                             rel='noreferrer'>諸塚でやま学校しよう！</a>」</span>
            </li>
          </ul>

          <h3>2013年1月(正月)</h3>
          <ul className='event-list'>
            <li>
              <span>1/1</span>
              <span>家代元旦祭・塚原神社元旦祭</span>
            </li>
            <li>
              <span>1/3</span>
              <span>第67回村成人式</span>
            </li>
            <li>
              <span>1/5</span>
              <span>八重の平山の神祭り</span>
            </li>
            <li>
              <span>1/6</span>
              <span>諸塚村消防団出初式</span>
            </li>
            <li>
              <span>1/26～1/27</span>
              <span>戸下夜神楽</span>
            </li>
            <li>
              <span>1/26～1/27</span>
              <span>エコツアー「<a href='https://www.morotsuka-tourism.jp/spot/spot_cat/yamagakkou/' target='_blank'
                             rel='noreferrer'>諸塚でやま学校しよう！</a>」</span>
            </li>
          </ul>

          <h3>2013年2月(如月)</h3>
          <ul className='event-list'>
            <li>
              <span>2/3</span>
              <span>南川神楽</span>
            </li>
            <li>
              <span>2/9</span>
              <span>七ッ山・奥畑鳥追いぜんぜほっほ</span>
            </li>
            <li>
              <span>2/10</span>
              <span>恵後の﨑 夜神楽</span>
            </li>
            <li>
              <span>2/24</span>
              <span>釜の前護王様祭</span>
            </li>
          </ul>

          <h3>2013年3月(弥生)</h3>
          <ul className='event-list'>
            <li>
              <span>3/3</span>
              <span>第28回諸塚山山開き</span>
            </li>
            <li>
              <span>3/17</span>
              <span>第6回世界森林認証祭</span>
            </li>
            <li>
              <span>3/24</span>
              <span>家代観音祭</span>
            </li>
            <li>
              <span>3/28</span>
              <span>吉野宮神社大祭（座頭神祭)</span>
            </li>
          </ul>

          {close}
        </article>

        <article
          id='picture-book'
          className={`${this.props.article === 'picture-book' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className='major'>樹の里図鑑</h2>

          <h3>ゴーヤ</h3>
          <p>
            色々な呼び方があるようですが、学名ではツルレイシというウリ科の植物だそうです。 宮崎では「ニガゴリ｣といいます。 ビタミンＣ等のビタミンを含み、どくとくの苦味の成分は胃腸にも効果があるようです。
            夏バテ防止に役立つ健康食品です。
          </p>

          <h3>タラの芽</h3>
          <p>
            日本各地に分布する。 伐採跡地や林道脇など日当たりの良い山林に生える。棘が多くあります。 春先に採取し、天ぷらにして食べます。
          </p>

          {close}
        </article>

        <article
          id='contact'
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className='major'>ご予約</h2>

          <a href='tel:0982650694' className='tel'>0982-65-0694</a>
          <p>
            メールでのご質問などは受けつけておりますが、ご予約は行なっておりません。<br />
            ご予約の際はお電話にてお願い致します。<br />
            ぜひお客様自身の「声」をお聞かせ下さい！
          </p>

          <h2 className='major'>アクセス</h2>

          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3364.16292172789!2d131.33834481516308!3d32.52179538104988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354735b8035f4a0b%3A0xa6972f53a00fb73a!2z44CSODgzLTEzMDEg5a6u5bSO55yM5p2x6Ie85p216YOh6Ku45aGa5p2R5a625Luj77yT77yS77yZ77yW!5e0!3m2!1sja!2sjp!4v1615622795984!5m2!1sja!2sjp'
            className='map' style={{ 'border': '0' }} allowFullScreen='' loading='lazy'></iframe>

          <ul className='access-list'>
            <li>住所: 宮崎県東臼杵郡諸塚村大字家代3296</li>
            <li>FAX: 0982-65-0702</li>
            <li>Mail: <a href='mailto:kinosato@r327.jp'>kinosato@r327.jp</a></li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes =
  {
    route: PropTypes.object,
    article: PropTypes.string,
    articleTimeout: PropTypes.bool,
    onCloseArticle: PropTypes.func,
    timeout: PropTypes.bool,
    setWrapperRef: PropTypes.func.isRequired,
}

export default Main