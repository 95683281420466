import PropTypes from 'prop-types'
import React from 'react'
import icon from '../images/icon.jpeg'

import localStyles from './header.module.scss'

const Header = props => (
  <header id='header' style={props.timeout ? { display: 'none' } : {}}>
    <div className={`logo ${localStyles.logo}`}>
      <img src={icon} alt='森の民宿 樹の里' />
    </div>
    <div className='content'>
      <div className='inner'>
        <h1 className={`title`}>
          {/*?xml version="1.0" standalone="no"?*/}
          <svg version={1.0} xmlns='http://www.w3.org/2000/svg'
               viewBox='0 0 262.000000 137.000000' preserveAspectRatio='xMidYMid meet'>
            <g transform='translate(0.000000,137.000000) scale(0.100000,-0.100000)' fill='#fff' stroke='none'>
              <title>森の民宿 樹の里</title>
              <desc>森の民宿 樹の里</desc>
              <path d='M668 1350 c-11 -17 -24 -20 -76 -20 -55 0 -63 -2 -60 -17 2 -13 16
-20 43 -23 l40 -5 -47 -28 c-33 -20 -45 -33 -41 -44 4 -9 14 -13 29 -10 18 3
24 0 24 -14 0 -15 -8 -19 -36 -19 -28 0 -35 -3 -32 -17 2 -10 15 -20 32 -23
l28 -5 -35 -36 c-24 -25 -32 -40 -27 -50 7 -11 15 -9 39 9 l31 23 0 -31 c0
-23 4 -30 20 -30 16 0 20 7 20 37 l0 37 31 -33 30 -34 34 33 34 33 3 -34 c2
-23 9 -35 21 -37 14 -3 17 4 17 32 0 20 3 36 6 36 3 0 17 -14 31 -31 38 -45
54 -22 20 29 -32 46 -33 52 -7 52 13 0 20 7 20 20 0 16 -7 20 -35 20 -28 0
-35 4 -35 19 0 17 5 19 35 14 50 -8 45 15 -13 54 l-47 33 36 0 c35 0 66 20 52
33 -3 4 -37 7 -74 7 -58 0 -67 3 -72 20 -3 11 -10 20 -16 20 -6 0 -16 -9 -23
-20z m20 -158 c20 -3 22 1 22 37 0 23 3 41 6 41 3 0 19 -11 35 -24 22 -19 25
-25 14 -30 -8 -3 -15 -15 -15 -26 0 -16 -7 -20 -30 -20 -23 0 -30 -4 -30 -20
0 -14 7 -20 21 -20 20 0 20 0 -1 -31 l-22 -30 -22 20 c-12 12 -28 21 -34 21
-24 0 -11 19 15 22 38 4 45 38 8 38 -18 0 -28 7 -36 27 -8 22 -6 30 13 50 l23
25 5 -39 c4 -30 10 -39 28 -41z' />
              <path d='M1865 1350 c-3 -5 -32 -10 -63 -10 -72 0 -84 -8 -80 -51 2 -26 8 -35
26 -37 29 -4 28 -15 -9 -57 -31 -35 -33 -65 -4 -65 12 0 15 -12 15 -54 0 -61
19 -88 45 -66 11 9 15 35 15 107 0 52 5 103 11 114 13 25 -6 53 -33 46 -11 -3
-18 0 -18 9 0 11 24 14 120 14 113 0 120 -1 120 -20 0 -18 -7 -20 -86 -20 -76
0 -85 -2 -82 -17 2 -12 14 -19 36 -21 40 -4 38 -25 -4 -32 -29 -5 -29 -6 -32
-78 -2 -46 2 -79 9 -88 15 -18 169 -20 187 -2 12 12 17 124 6 152 -4 11 -19
16 -51 16 -58 0 -54 24 5 28 38 3 44 7 58 40 11 27 12 43 5 59 -9 20 -18 23
-75 23 -36 0 -68 5 -71 10 -3 6 -15 10 -25 10 -10 0 -22 -4 -25 -10z m130
-215 c0 -11 -69 -19 -92 -11 -8 3 -11 11 -8 16 8 14 100 9 100 -5z m3 -67 c-3
-8 -23 -13 -53 -13 -30 0 -50 5 -52 13 -4 9 11 12 52 12 41 0 56 -3 53 -12z' />
              <path d='M1352 1338 c-8 -8 -12 -52 -12 -139 0 -104 -3 -128 -15 -133 -20 -7
-19 -45 1 -53 9 -3 50 2 91 11 66 16 74 20 71 39 -3 19 -9 22 -50 20 -47 -1
-48 0 -48 28 0 29 1 29 55 29 55 0 55 0 71 -40 18 -44 65 -90 94 -90 10 0 28
12 39 26 16 21 18 30 10 46 -10 18 -12 18 -28 4 -26 -23 -39 -20 -61 17 l-20
32 53 5 c44 4 52 8 52 25 0 17 -8 20 -57 23 -49 3 -58 6 -58 22 0 15 9 19 48
22 l47 3 0 55 0 55 -135 3 c-97 2 -139 -1 -148 -10z m236 -50 c3 -16 -7 -18
-97 -18 -78 0 -101 3 -101 13 0 25 13 28 104 25 77 -3 91 -6 94 -20z m-98 -78
c0 -17 -7 -20 -50 -20 -43 0 -50 3 -50 20 0 17 7 20 50 20 43 0 50 -3 50 -20z' />
              <path d='M1006 1297 c-98 -56 -107 -234 -12 -245 53 -7 126 109 126 200 0 26
3 29 23 23 12 -4 32 -18 45 -31 45 -50 17 -130 -55 -161 -44 -18 -42 -49 2
-46 59 5 125 79 125 142 0 50 -29 101 -70 123 -50 26 -134 24 -184 -5z m64
-44 c0 -58 -47 -153 -75 -153 -21 0 -28 41 -16 88 16 59 91 112 91 65z' />
              <path d='M485 836 c-9 -21 -20 -68 -24 -105 -5 -38 -14 -77 -20 -89 -10 -20
-58 -35 -146 -48 -178 -25 -258 -62 -277 -129 -14 -52 -13 -73 7 -95 33 -36
62 -34 159 13 92 45 179 72 197 62 27 -17 -18 -120 -95 -219 -25 -32 -46 -62
-46 -67 0 -8 -59 -66 -125 -123 -20 -17 -25 -25 -15 -26 14 0 67 36 160 108
70 54 103 73 124 70 18 -3 21 -10 22 -50 2 -54 16 -78 49 -78 45 0 60 34 58
126 l-3 84 31 0 c25 0 32 -6 45 -35 8 -19 23 -41 34 -47 29 -18 25 -33 -15
-64 -40 -30 -43 -40 -15 -49 18 -6 65 6 158 40 51 18 152 92 152 111 0 17 -34
27 -70 19 -28 -6 -30 -5 -30 24 0 22 7 35 26 47 32 21 54 64 54 105 0 25 3 30
18 26 9 -4 37 -11 62 -18 25 -6 51 -13 58 -15 6 -2 12 -17 12 -34 0 -35 -15
-38 -62 -14 -30 15 -32 15 -45 -2 -26 -35 -14 -75 54 -179 48 -74 91 -125 105
-125 5 0 17 15 28 32 16 27 19 44 14 96 -5 59 -4 64 16 69 21 6 21 7 -8 37
-56 59 -55 124 2 144 59 22 55 84 -7 100 l-32 8 0 122 c0 73 -4 123 -10 124
-15 4 -34 -63 -47 -168 l-11 -94 -53 0 c-29 0 -59 3 -68 6 -22 9 -20 31 4 39
32 10 23 23 -22 30 -43 7 -68 20 -68 36 0 5 14 24 31 43 33 38 31 56 -6 56
-14 0 -32 12 -47 30 -65 86 -76 95 -95 84 -18 -12 -19 -17 -7 -66 6 -25 4 -28
-19 -28 -57 0 -84 12 -98 43 -8 17 -29 40 -47 50 l-32 19 -15 -36z m172 -153
c24 -8 45 -19 49 -24 9 -15 -63 -10 -76 6 -16 20 -46 19 -54 -1 -10 -27 3 -53
35 -69 16 -9 29 -20 29 -26 0 -10 -34 7 -74 36 -18 14 -18 16 -1 60 13 36 21
45 34 40 9 -4 35 -14 58 -22z m196 -163 c-6 -23 -12 -24 -36 -6 -14 11 -15 15
-5 19 30 12 46 7 41 -13z m-263 -62 c0 -7 -3 -30 -6 -50 -6 -34 -10 -38 -35
-38 -24 0 -29 4 -29 24 0 46 18 76 45 76 14 0 25 -6 25 -12z m185 -78 c-8 -13
-62 -13 -70 0 -4 6 -1 17 5 25 10 13 16 12 41 -1 16 -8 27 -19 24 -24z m-67
-90 c40 0 40 -11 2 -52 -23 -26 -29 -29 -30 -15 0 10 -10 28 -23 40 -13 12
-26 29 -30 39 -5 14 -3 15 21 2 15 -8 42 -14 60 -14z m322 -10 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z' />
              <path d='M1768 853 c-32 -38 -52 -115 -52 -203 0 -73 4 -93 29 -140 15 -30 35
-60 44 -67 10 -7 49 -12 99 -13 61 0 82 -3 82 -13 0 -8 -17 -18 -37 -23 -31
-7 -38 -13 -38 -34 0 -21 7 -27 41 -35 23 -5 56 -22 74 -36 33 -25 33 -27 24
-70 l-10 -44 -48 3 c-72 5 -194 83 -225 145 -20 39 -67 68 -80 49 -9 -15 -8
-97 2 -127 10 -29 167 -133 218 -144 159 -36 210 -40 354 -26 257 27 272 29
304 53 17 12 34 22 39 22 13 0 33 26 26 33 -3 3 -30 -6 -60 -20 -29 -13 -62
-22 -72 -19 -9 3 -91 7 -181 7 l-163 2 7 36 c7 37 30 71 48 71 23 0 98 43 108
62 17 31 -13 68 -55 68 -66 0 -72 55 -8 66 118 19 140 43 124 136 -6 35 -14
84 -17 111 -4 28 -15 57 -28 72 -20 22 -31 25 -142 30 -66 3 -140 10 -165 15
-25 5 -79 16 -120 24 -42 8 -82 18 -91 21 -8 3 -22 -2 -31 -12z m518 -188 c6
-33 16 -72 22 -87 16 -39 -6 -58 -69 -58 -58 0 -67 21 -19 48 56 32 51 72 -8
72 -43 0 -55 7 -71 37 -13 26 -7 31 51 43 76 15 81 12 94 -55z m-309 27 c6 -4
13 -16 15 -27 3 -18 -3 -20 -44 -21 -51 -1 -61 11 -32 40 16 17 42 20 61 8z
m60 -157 c3 -9 4 -18 1 -20 -2 -3 -39 -7 -81 -10 -71 -5 -77 -4 -77 13 0 29 8
32 82 32 53 0 70 -4 75 -15z' />
              <path d='M1274 549 c-50 -40 -54 -46 -43 -83 10 -33 68 -147 106 -209 21 -33
28 -38 56 -35 45 4 76 68 77 156 0 55 2 62 20 62 23 0 41 -35 45 -86 3 -42 12
-54 39 -54 24 0 56 28 56 48 0 18 -33 82 -42 82 -4 0 -17 18 -28 39 -25 50
-72 85 -132 100 -78 18 -111 14 -154 -20z m138 -115 c-3 -52 -19 -104 -32
-104 -14 0 -41 31 -51 57 -15 39 -11 62 15 88 19 19 30 23 48 16 19 -7 22 -14
20 -57z' />
            </g>
          </svg>

        </h1>
        <h2 className='subtitle'>山の幸、美味しく食べて健康に</h2>
        <p>
          山懐に抱かれ、花と緑に囲まれた静かな宿です。<br />四季折々の諸塚産食材でおもてなし致します。
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            お部屋
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('price')
            }}
          >
            料金
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('food')
            }}
          >
            お食事
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('event')
            }}
          >
            イベント
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('picture-book')
            }}
          >
            樹の里図鑑
          </button>
        </li>
        <li>
          <a href='https://kinosato.exblog.jp/' target='_blank' rel='noopener noreferrer'>
            <button
            >
              ブログ
            </button>
          </a>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            アクセス&ご予約
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
